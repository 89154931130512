*,
*:after,
*:before {
  box-sizing: inherit;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
}

html {
  font-size: 10px;
  font-family: 'LL Circular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  box-sizing: border-box;
}
/* Keep size of tablet when rotating it. */
@media (min-width: 600px) and (max-width: 1199px) {
  html {
    font-size: 8px;
  }
}

body {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input {
  font-family: 'Lato', sans-serif;
}

strong {
  font-weight: 700;
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}
